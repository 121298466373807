<template>
  <div class="main-content">
    <div class="page-head-tile">
      <div class="container">
        <div class="breadcrumbs-block">
          <ul class="breadcrumbs">
            <li class="bc-item">
              <router-link :to="{name: 'index'}">Главная</router-link>
              <i class="fa fa-angle-right"></i>
            </li>
            <li class="bc-item">
              <router-link :to="{name: 'documents'}">Документы</router-link>
              <i class="fa fa-angle-right"></i>
            </li>
            <li class="bc-item">
              <router-link :to="{ name: 'open_category', params: {catid: $store.state.category.id}}">{{ $store.state.category.title }}</router-link>
            </li>
          </ul>
        </div>
        <div class="page-title">
          <h1 class="title-16"><strong>{{ $store.state.category.title }}</strong></h1>
        </div>
      </div>
    </div>
    <div class="section post-section">
      <div class="row">
        <div class="full">
          <div class="pst-block">
            <div class="pst-block-main">
              <div class="post-content">
                <article>
                  <div class="post-main-img">
                    <div class="post-main-overlay">
                      <div class="post-main-data"></div>
                    </div>
                  </div>
                  <div v-if="$store.state.category.get_document_content">
                    <p v-for="(document, index) in $store.state.category.get_document_content" :key="index">
                      <router-link :to="{ name: 'open_documents', params: {catid: document.category_id, id: document.id}}">{{document.title}}</router-link>
                    </p>
                    <p v-if="!$store.state.category.get_document_content.length">Нет добавленных документов</p>
                  </div>
                  <!--<div v-html="$store.state.project.description" v-if="$store.state.project.description"></div>-->
                  <!--<div v-html="$store.state.project.content" v-if="$store.state.project.content"></div>-->
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CategoryDocument',
  async asyncData (context) {
    await context.store.dispatch('documentsListPage');
    await context.store.commit('document', context.route.params);
  },
  methods: {
    async parsePage (url) {
      if (url !== null) {
        await this.$store.dispatch('documentsListPage', url);
      }
    }
  },
  metaInfo () {
    return this.$seo(this.$store.state.category.title || 'Документы')
  }
};
</script>
